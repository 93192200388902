<template>
  <div v-if="quantity <= 0" class="btn-sold bg-white">
    <StockOutText />
  </div>

  <div class="bg-white flex items-center gap-2.5 p-1.5" v-else>
    <div v-if="quantity !== 0 && selectedVariant?.unitType !== 2 && !isProductVariableCoefficientUnitCalculationIsActive">
      <FormInputCounter input-size="sm" v-model="cartQuantity" :min="1" :max="maxQtyForProduct(product)" :step="quantityStep"/>
    </div>

    <div class="w-full flex justify-end items-center">
      <FormButton
          color="gray"
          size="sm"
          type="button"
          class=""
          :loading="addCartLoading === selectedVariant.variantId"
          @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, selectedVariant?.primaryStockData.warehouseId, null, false, false, finalPrice, null, cartAttributes)"
      >
        <template #icon>
          <LucidePlus class="size-4 mr-2"/>
        </template>
        {{ $t('app.add-to-cart') }}
      </FormButton>
    </div>
  </div>
</template>

<script setup>
import StockOutText from "~/components/StockOutText.vue";

const props = defineProps(['selectedVariant'])

const storeConfig = useStoreConfig()
const { isProductVariableCoefficientUnitCalculationIsActive } = storeToRefs(storeConfig)

const {
  product,
  cartAttributes,
  finalPrice
} = await useProduct()

const {
  addCartLoading,
  addToBasket
} = await useBasket()

const {maxQtyForProduct} = useListing()

const cartQuantity = ref(1)

const quantity = computed(() => props.selectedVariant.primaryStockData.quantity)
const quantityStep = computed(() => props.selectedVariant?.primaryStockData?.quantityStep)

</script>