<template>
  <div class="product-container">
    <header class="hidden sm:flex justify-start">
      <div>
        <slot name="breadcrumbs" />
      </div>
    </header>

    <div class="product-detail-wrapper">

      <!-- Left Side -->
      <div class="left-content">
        <slot name="slider" />
      </div>

      <!-- Right Side -->
      <div class="right-content">
        <div class="right-content--wrapper">
          <div class="">
            <slot name="brand" />
            <slot name="title" />
            <slot name="modelSku" />
            <slot name="reviewsInfo" />
          </div>

          <div class="space-y-4">
            <!-- Toptan Fiyatı -->
            <slot name="wholesalePrice" />

            <!-- B2C Fiyatı -->
            <slot name="productPrice" />

            <!-- Kampanya Bilgisi -->
            <slot name="discountLabel" />

            <!-- Son Kalan Ürünler -->
            <slot name="productCriticalQuantity" />
          </div>

          <!-- Renk Seçenekleri -->
          <!-- <slot name="colorOptions" /> -->

          <!-- Beden Seçenekleri -->
          <slot name="sizeOptions" />

          <!-- Taksit Bilgisi - X TL'den Başlayan Fiyatlarla -->
          <slot name="installmentInfo" />

          <!-- Ürün Özellikleri -->
          <slot name="productAttributes"></slot>

<!--          <div class="mt-6">-->
            <!-- Birim -->
<!--            <slot name="unitInfo" />-->

            <!-- Sepete Ekle, Favorilere Ekle vb -->
<!--            <slot name="shopButtons" />-->
<!--          </div>-->

          <!-- Öne Çıkan Site Özellikleri -->
          <slot name="highlights" />

        </div>
      </div>
    </div>

    <!-- Ürün Özellikleri -->
    <slot name="metaInformations"/>

    <!-- Ürün Renk Seçenekleri -->
    <SectionProductColorOptionsFull />

    <!-- Benzer Ürünler -->
    <slot name="relatedProducts" />
  </div>
</template>

<script setup>
import "assets/css/modules/product-detail-optik.css"

const {selectedVariant} = await useProduct();

</script>
